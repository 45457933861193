<!--
  -- Created by zed on 2022/10/14
  -- 【商品修改页面】
-->
<template>
  <moe-page title="修改商品">
    <div class="goods-amend">
      <div class="goods-amend-header">
        <el-menu
          mode="horizontal"
          :default-active="activeIndex"
          class="el-menu-demo"
          @select="handleSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b">
          <el-menu-item index="1">
            <span slot="title">基础信息</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">销售信息</span>
          </el-menu-item>
          <el-menu-item index="3">
            <span slot="title">物流信息</span>
          </el-menu-item>
          <el-menu-item index="4">
            <span slot="title">图文描述</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="goods-amend-content">
        <div class="goods-amend-content-scroll">
          <moe-card class="mb-20">
            <div class="df aic">
              <div>当前类目：{{ category.map(({ name }) => name).join('>>') }}</div>
            </div>
          </moe-card>

          <moe-card class="mb-20 scrollIntoView1" id="scrollIntoView1">
            <template slot="header">
              基础信息
            </template>
            <moe-form ref="goodsForm" :model="goodsParams" :rules="goodsRules" :showBack="false" :showTool="false" :defaultVerify="false">
              <el-form-item label="商品详情展示风格" prop="style.mode">
                <moe-select v-model="goodsParams.style.mode" placeholder="请选择商品详情展示风格" type="goodStyle"></moe-select>
              </el-form-item>
              <el-form-item label="商品名称" prop="name">
                <el-input v-model.trim="goodsParams.name" placeholder="请输入商品名称" maxlength="50" clearable />
              </el-form-item>
              <el-form-item label="商品主图" prop="coverUrl">
                <moe-upload-file
                  v-model="goodsParams.coverUrl"
                  ossKey="SHOP_MEDIA"
                  upload-text="上传商品主图"
                  :default-file-list="coverUrlList"
                  @change="$refs.goodsForm.validateField('coverUrl')" />
              </el-form-item>
              <el-form-item label="商品视频">
                <moe-upload-file
                  v-model="goodsParams.videoUrl"
                  :size="100"
                  type="video"
                  ossKey="SHOP_MEDIA"
                  upload-text="上传商品视频"
                  :default-file-list="videoUrlList"
                  @change="() => $refs.goodsForm.validateField('videoUrl')" />
              </el-form-item>
              <el-form-item label="商品轮播图" prop="imgsUrl">
                <moe-upload-file
                  v-model="goodsParams.imgsUrl"
                  ossKey="SHOP_MEDIA"
                  :limit="8"
                  :multiple="true"
                  upload-text="上传商品轮播图"
                  :default-file-list="imgsUrlList"
                  :getFileRatio="true"
                  :ratio.sync="goodsParams.ratio"
                  @change="$refs.goodsForm.validateField('imgsUrl')" />
              </el-form-item>
              <el-form-item label="关联品牌">
                <el-select v-model="goodsParams.brandId" filterable placeholder="选择关联品牌" clearable>
                  <el-option
                    v-for="(item, index) in brandList"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="虚拟销量">
                <el-input :value="goodsParams.fakeBuyNum" @input="(value) => goodsParams.fakeBuyNum = value.replace(/^\.+|[^\d]/g, '')"  placeholder="请输入虚拟销量" maxlength="10"></el-input>
              </el-form-item>
              <el-form-item label="商品介绍">
                <el-input type="textarea" autosize placeholder="请输入商品介绍" maxlength="200" clearable v-model="goodsParams.introduce"></el-input>
              </el-form-item>
            </moe-form>
          </moe-card>
          <moe-card class="mb-20 scrollIntoView2" id="scrollIntoView2">
            <template slot="header">
              销售信息
            </template>
            <moe-specs ref="moeSpecsRef" :isEdit="true" :disabled="status === 1"></moe-specs>
          </moe-card>
          <moe-card class="mb-20 scrollIntoView3" id="scrollIntoView3">
            <template slot="header">
              物流信息
            </template>
            <moe-form ref="freightForm" :model="goodsParams" :rules="goodsRules" :showBack="false" :showTool="false" :defaultVerify="false">
              <el-form-item label="供应商" prop="vendorId">
                <el-select :disabled="status === 1" v-model="goodsParams.vendorId" filterable placeholder="请选择供应商" clearable @change="(value) => changeVendor(value)">
                  <el-option
                    v-for="(item, index) in supplierList"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item  label="运费模板" prop="freightTplId">
                <div class="df aic">
                  <el-select :disabled="status === 1" v-model="goodsParams.freightTplId" filterable placeholder="请选择运费模板" clearable>
                    <el-option
                      v-for="(item, index) in freightList"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <el-tooltip class="ml-10" content="请先选择供应商，若无对应运费模板请先前往新建运费模板" placement="top">
                    <moe-icon name="reminder" size="16px"></moe-icon>
                  </el-tooltip>
                </div>
              </el-form-item>
            </moe-form>
          </moe-card>
          <moe-card class="scrollIntoView4" id="scrollIntoView4">
            <template slot="header">
              图文描述
            </template>
            <moe-form class="default-form" ref="goodsForm2" :model="goodsParams" :rules="goodsRules" :showBack="false" :defaultVerify="false" :showTool="false">
              <el-form-item label="商品详情" prop="infoPics">
                <moe-tinymce height="800" v-model="goodsParams.infoPics"></moe-tinymce>
              </el-form-item>
            </moe-form>
          </moe-card>
          <moe-form>
            <template slot="tool">
              <el-button type="primary" icon="el-icon-upload2" :loading="goodsLoad" @click="submit()">{{ goodsLoad ? '保存中': '保存' }}</el-button>
            </template>
          </moe-form>
        </div>
      </div>
    </div>
  </moe-page>
</template>

<script>
export default {
  name: 'GoodsAmend',
  data() {
    const goodsParams = {
      id: '',
      name: '',           //商品名称
      coverUrl: '',       //商品主图
      videoUrl: '',       //商品视频
      imgsUrl: [],        //商品轮播图
      fakeBuyNum: '',     //虚拟销量
      introduce: '',      //商品介绍
      style: {
        mode: 'default',
      },
      infoPics: '',       //商品详情
      brandId: '',        //品牌编号
      vendorId: '',       //供应商编号
      delFlag: 0,         //删除状态 0：正常 1：删除
      ratio: [],          //商品轮播图 图片比率
      goodsSpec: [],      //商品规格
      shopGoodsItem: [],  //商品sku
      freightTplId: '',   //运费模版
    }
    const verify = this.$moe_verify.verifyForm
    const goodsRules = {
      name: verify.isExtent(['请输入设备名称', '长度为 1 ~ 50'], '1~50'),
      coverUrl: verify.isEmpty('请上传商品主图'),
      imgsUrl: verify.isEmpty('请上传商品轮播图'),
      fakeBuyNum: verify.isEmpty('请输入虚拟销量'),
      introduce: verify.isEmpty('请输入商品介绍'),
      infoPics: verify.isEmpty('请输入商品详情'),
      brandId: verify.isEmpty('请选择关联品牌'),
      [`style.mode`]: verify.isEmpty('请选择商品详情展示风格'),
      vendorId: verify.isEmpty('请选择供应商'),
      freightTplId: verify.isEmpty('请选择运费模板'),
    }
    return {
      activeIndex: '1',
      category: [],
      goodsParams,
      goodsRules,
      videoUrlList: [],
      coverUrlList: [],
      imgsUrlList: [],
      brandList: [],
      freightList: [],
      supplierList: [],
      goodsLoad: false,
      status: 1,
    }
  },
  methods: {
    /** 分页查询品牌列表 */
    getBrandList() {
      this.$moe_api.BRAND_API.getBrandList({ pageNum: 1, pageSize: 9999, status: true }).then((data) => {
        if (data.code === 200) {
          this.brandList = data.result.list.map(({ name, id }) => {
            return {
              label: name,
              value: id,
            }
          })
        }
      })
    },
    async submit() {
      let goodsForm = new Promise((resolve, reject) => {
        this.$refs['goodsForm'].validate(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });

      let moeSpecsRef = new Promise((resolve, reject) => {
        this.$refs['moeSpecsRef'].$refs['specsForm'].validate(() => {
          this.$refs['moeSpecsRef'].$refs['tableForm'].validate(() => {
            resolve();
          }).catch(() => {
            reject();
          });
        }).catch(() => {
          reject();
        });
      });

      let freightForm = new Promise((resolve, reject) => {
        this.$refs['freightForm'].validate(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      })

      let goodsForm2 = new Promise((resolve, reject) => {
        this.$refs['goodsForm2'].validate(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });

      await Promise.all([
        goodsForm,
        moeSpecsRef,
        freightForm,
        goodsForm2
      ]).then(() => {
        this.goodsLoad = true
        let goodsSpec = this.$refs['moeSpecsRef'].form.goodsSpec.map(({ name, goodsId, id, specValue, checkList }) => {
          let delFlagList = this.$moe_lodash.differenceByList(checkList, specValue, 'id').map((item) => {
            return {
              ...item,
              delFlag: 1,
            }
          }).filter(({ id }) => id)
          return {
            delFlag: 0,
            goodsId,
            id,
            name,
            specValue: [...specValue.map(({ value, seat, id, goodsId, specId, delFlag }) => {
              return {
                delFlag: 0,
                goodsId,
                id,
                seat,
                specId,
                value,
              }
            }), ...delFlagList]
          }
        });

        let shopGoodsItem = this.$refs['moeSpecsRef'].form.shopGoodsItem.map(({ barCode, costPrice, coverUrl, quota, salePrice, specValueIds, specValueStr, identifier, stock, volume, weight, goodsId, id }) => {
          return {
            barCode,
            costPrice,
            coverUrl,
            delFlag: 0,
            goodsId,
            id,
            quota,
            salePrice,
            specValueIds: specValueIds.join(','),
            specValueStr: Array.isArray(specValueStr) ? specValueStr.join(',') : specValueStr,
            status: 1,
            identifier,
            stock,
            volume,
            weight
          }
        });

        this.$moe_api.GOODS_API.updateGoods({
          ...this.goodsParams,
          fakeBuyNum: this.goodsParams.fakeBuyNum || 0,
          style: JSON.stringify(this.goodsParams.style),
          imgsUrl: this.goodsParams.imgsUrl,
          ratio: JSON.stringify(this.goodsParams.ratio),
          goodsSpec,
          shopGoodsItem,
        }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('保存成功');
            this.$router.go(-1);
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.goodsLoad = false
        })
      }).catch(() => {
        this.goodsLoad = false;
        this.$moe_msg.warning('请完善信息 !');
      })
    },
    /** 菜单按钮 */
    handleSelect(key) {
      let el = document.querySelector(`.scrollIntoView${key}`);
      el.scrollIntoView(true);
    },
    changeVendor(value) {
      this.req_getFreightTplList(value, true);
    },
    /** 异步观察目标元素与其祖先元素或顶级文档视口（viewport）交叉状态的方法 */
    intersectionObserver() {
      const io = new IntersectionObserver(entries => {
        let item = entries[0]
        switch (item.target.id) {
          case 'scrollIntoView1':
            if (item.intersectionRatio > 0) {
              this.activeIndex = '1'
            } else if (item.intersectionRatio === 0) {
              this.activeIndex = '2'
            }
            break;

          case 'scrollIntoView2':
            if (item.intersectionRatio > 0) {
              this.activeIndex = '2'
            } else if (item.intersectionRatio === 0) {
              this.activeIndex = '3'
            }
            break;

          case 'scrollIntoView3':
            if (item.intersectionRatio > 0) {
              this.activeIndex = '3'
            } else if (item.intersectionRatio === 0) {
              this.activeIndex = '4'
            }
            break;

          case 'scrollIntoView4':
            if (item.intersectionRatio === 1) {
              this.activeIndex = '4'
            }
            break;
        }
      }, {
        thresholds: [0, 1],
        root: document.querySelector(`.goods-amend-content-scroll`)
      })
      const scrollIntoView1 = document.querySelector('.scrollIntoView1')
      const scrollIntoView2 = document.querySelector('.scrollIntoView2')
      const scrollIntoView3 = document.querySelector('.scrollIntoView3')
      const scrollIntoView4 = document.querySelector('.scrollIntoView4')
      io.observe(scrollIntoView1)
      io.observe(scrollIntoView2)
      io.observe(scrollIntoView3)
      io.observe(scrollIntoView4)
    },
    /** 分页查询运费模板 */
    req_getFreightTplList(vendorId, reset) {
      if (reset) {
        this.goodsParams.freightTplId = '';
      }
      if (vendorId) {
        this.$moe_api.FREIGHTTPL_API.getFreightTplList({ pageNum: 1, pageSize: 999, vendorId }).then((data) => {
          if (data.code === 200) {
            this.freightList = data.result.list.map(({ name, id }) => {
              return {
                label: name,
                value: id
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      } else {
        this.freightList.splice(0);
      }
    },
    /** 分页查询供应商列表 */
    req_getVendorList() {
      this.$moe_api.SUPPLIER_API.getVendorList({ pageNum: 1, pageSize: 999, status: true }).then((data) => {
        if (data.code === 200) {
          this.supplierList = data.result.list.map(({ name, id }) => {
            return {
              label: name,
              value: id
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    window.addEventListener('beforeunload', (event) => {
      // 显示确认对话框
      event.preventDefault();
      // Google Chrome 需要设置 returnValue.
      event.returnValue = '';
    });

    this.intersectionObserver();
    this.$moe_api.GOODS_API.queryShopGoodsById({ id: this.$route.query.id }).then((data) => {
      if (data.code === 200) {
        const { category, id, style, name, coverUrl, imgsUrl, videoUrl, fakeBuyNum, introduce, infoPics, brandId, vendorId, delFlag, ratio, spec, goodsItem, freightTplId, status } = data.result;
        this.category = category;
        this.status = status;
        this.goodsParams = {
          id,
          style: style ? JSON.parse(style) : { mode: '' },
          name,
          coverUrl: this.$moe_yunHelper.formatterImageUrl(coverUrl),
          videoUrl: videoUrl ? this.$moe_yunHelper.formatterImageUrl(videoUrl) : '',
          imgsUrl: this.$moe_yunHelper.formatterImageUrl(imgsUrl),
          fakeBuyNum,
          introduce,
          infoPics,
          brandId,
          vendorId,
          delFlag,
          ratio: ratio ? JSON.parse(ratio) : [],
          goodsSpec: spec.map(({ delFlag, goodsId, name, id, specValue }) => {
            return {
              delFlag,
              goodsId,
              name,
              id,
              specValue,
              valueList: specValue.map((specValueItem) => {
                return {
                  ...specValueItem,
                }
              }),
            }
          }),
          shopGoodsItem: goodsItem.map((item) => {
            return {
              ...item,
              specValueIds: item.specValueIds.split(','),
              coverUrl: this.$moe_yunHelper.formatterImageUrl(item.coverUrl),
              imgsUrlList: [
                {
                  url: item.coverUrl,
                  tempFilePath: this.$moe_yunHelper.getTempFilePath(item.coverUrl),
                }
              ]
            }
          }),
          freightTplId
        }
        if (vendorId) {
          this.req_getFreightTplList(vendorId);
        }
        if (coverUrl) {
          this.coverUrlList = coverUrl.split(',').map((item) => {
            return {
              url: item,
              tempFilePath: this.$moe_yunHelper.getTempFilePath(item),
            }
          })
        }
        if (imgsUrl) {
          this.imgsUrlList = imgsUrl.split(',').map((item) => {
            return {
              url: item,
              tempFilePath: this.$moe_yunHelper.getTempFilePath(item),
            }
          })
        }
        if (videoUrl) {
          this.videoUrlList = [
            {
              url: videoUrl,
              tempFilePath: this.$moe_yunHelper.getTempFilePath(videoUrl),
            }
          ]
        }

        this.$refs['moeSpecsRef'].shopGoodsItem = this.goodsParams.shopGoodsItem;
        this.$refs['moeSpecsRef'].init(this.goodsParams.goodsSpec);
      } else {
        this.$moe_msg.error(data.message)
      }
    })
    this.getBrandList();
    this.req_getVendorList();
  }
}
</script>

<style lang="scss" scoped>
.goods-amend {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-header {
    margin-bottom: 20px;
  }

  &-content {
    flex: 1;
    position: relative;
    width: 100%;

    &-scroll {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
    }
  }
}
</style>
